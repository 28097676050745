import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import Link from "next/link";
import LogoCSDBlack from "./Logos/LogoCSDBlack";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    flexGrow: 1,
    background: "#fff",
  },
}));

const SplashError = ({ label }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <Box py={4}>
          <LogoCSDBlack />
        </Box>
        <Typography variant="h4">
          {label}
        </Typography>
        <Typography paragraph variant="h2">
          :(
        </Typography>
        <Box py={4}>
          <Link href="/" passHref>
            <Button variant="contained" color="primary">
              <Typography variant="h6">Go Back</Typography>
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SplashError;
